.burger-menu-btn {
	height: 32px;
	width: 40px;
	cursor: pointer;
	display: none;
}

.burger-menu-btn span,
.burger-menu-btn span::before,
.burger-menu-btn span::after {
	background: var(--text-color);
	border-radius: 3px;
	content: '';
	position: absolute;
	width: 40px;
	height: 3px;
	margin-top: 13px;

	-webkit-transition: 0.2s ease-in-out;
	-moz-transition: 0.2s ease-in-out;
	-o-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
}

.burger-menu-btn span::before {
	margin-top: -12px;
}

.burger-menu-btn span::after {
	margin-top: 12px;
}

.burger-menu-btn.active span {
	background: transparent;
}

.burger-menu-btn.active span::before {
	margin-top: 0;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.burger-menu-btn.active span::after {
	margin-top: 0;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

@media screen and (max-width: 768px) {
	.burger-menu-btn {
		display: block;
	}
}
