.contacts-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 50px;
	height: 100%;
	width: 100%;
}
.contacts-body {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 30px;
}
.contacts-footer {
	height: 60px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
}
.contacts-form {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-areas:
		'name email'
		'text text'
		'button button';
	row-gap: 50px;
	column-gap: 60px;
	align-items: center;
	padding-right: 15%;
}
.contacts-form button {
	opacity: 0;
	transform: translateY(10px);
}

.input-name {
	grid-area: name;
}
.input-email {
	grid-area: email;
}
.input-message {
	grid-area: text;
}
.input-wrapper {
	position: relative;
	height: 30px;
	line-height: 30px;
	font-family: 'Montserrat', sans-serif;
	font-size: 1rem;
}
input {
	position: absolute;
	width: 100%;
	outline: none;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 2px solid var(--text-color);
	background-color: transparent;
	color: var(--text-color);
	transition: all 0.1s ease;
	z-index: 111;
	opacity: 0;
	width: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-background-clip: text;
	transition: background-color 5000s ease-in-out 0s;
	-webkit-text-fill-color: var(--text-color);
}

.labelline {
	position: absolute;
	font-size: 0.9rem;
	transition: all 0.2s ease;
	opacity: 0;
}

input:focus,
input:valid {
	border-color: var(--secondary-color);
}
input:focus + .labelline,
input:valid + .labelline {
	transform: translateY(-30px);
	font-size: 0.7rem;
	color: var(--secondary-color);
}

address {
	width: 100%;
	height: 20px;
	display: flex;
	align-items: space-between;
	justify-content: flex-start;
	gap: 50px;
	font-size: 1rem;
}
address p {
	margin: 0;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	color: var(--text-color);
}

.email,
.phone {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}
.email a,
.phone a {
	color: var(--secondary-color);
	text-decoration: none;
	font-family: Roboto Mono, Verdana, sans-serif;
}

@media screen and (max-width: 768px) {
	.contacts-form {
		padding-right: 0;
	}
	address {
		width: 100%;
		flex-direction: column;
		gap: 15px;
		align-items: flex-start;
	}
}

@media screen and (max-width: 480px) {
	.contacts-form {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		grid-template-areas:
			'name'
			'email'
			'text'
			'button';
		row-gap: 35px;
	}
	.input-message {
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 340px) {
	.contacts-wrapper {
		gap: 20px;
	}
}
