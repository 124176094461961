.text-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 30px;
	opacity: 0;
	transform: translateY(20px);
}
.text {
	font-family: 'Montserrat', sans-serif;
	font-size: clamp(1.1rem, 1.8vw, 1.2rem);
	line-height: 1.5;
	text-align: left;
	margin: 0;
	padding: 0 5px;
}

h1,
h2 {
	font-family: 'Roboto Slab', serif;
	font-weight: 400;
	font-size: clamp(1.7rem, 4vw, 2.4rem);
	opacity: 0;
	transform: translateY(20px);
}
.paige-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	gap: 40px;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
.page-not-found-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}

.about-title {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	position: relative;
	z-index: 1;
	opacity: 0;
	transform: translateY(20px);
}
.about-title__image {
	width: 200px;
	height: 200px;
	border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}
.about-title-name {
	opacity: 1;
}
/*_________________________________________________________________________*/
@media screen and (max-width: 768px) {
	h1,
	h2 {
		align-self: center;
	}
}
@media screen and (max-width: 430px) {
	.about-title {
		width: 100%;
		flex-direction: column;
		gap: 5px;
	}
	.about-title__image {
		width: 180px;
		height: 180px;
	}
}

@media screen and (max-width: 340px) {
	.paige-wrapper {
		gap: 20px;
	}
	h1,
	h2 {
		font-size: 1.5rem;
	}
}
