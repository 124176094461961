.box-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--bg-color);
	padding: 30px 20px;
	z-index: 100;
	border: 2px solid var(--text-color);
	border-radius: 5px;
	display: flex;
	gap: 20px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 90%;
	max-width: 600px;
	color: var(--text-color);
}
.modal-title {
	font-size: 3rem;
	color: var(--secondary-color);
}
